// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const enabledGestures = {"I6479:171921;1054:1582": {hover: true}};

const cycleOrder = ["I6479:171921;1054:1582"];

const variantClassNames = {"I6479:171921;1054:1582": "framer-v-waxlco"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, width, ...props}) => { return {...props, lZ0cdWU_m: link ?? props.lZ0cdWU_m} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, lZ0cdWU_m, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I6479:171921;1054:1582", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-BWn3s", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={lZ0cdWU_m} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-waxlco", className)} framer-1765g9y`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I6479:171921;1054:1582"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(0, 44, 21)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(0, 44, 21)", borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, ...style}} variants={{"I6479:171921;1054:1582-hover": {backgroundColor: "var(--token-fe5e5c53-7273-4586-ac08-e5dcf8b15495, rgb(182, 243, 16))"}}} {...addPropertyOverrides({"I6479:171921;1054:1582-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-15oyq4-container"} layoutDependency={layoutDependency} layoutId={"W1WA0msQ3-container"}><Feather color={"var(--token-3070a07a-a5cf-45eb-af38-7f6ebdd9dbe7, rgb(195, 245, 60)) /* {\"name\":\"Lime/300\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"chevron-up"} id={"W1WA0msQ3"} layoutId={"W1WA0msQ3"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"I6479:171921;1054:1582-hover": {color: "var(--token-6f1336a9-4a56-423a-b8b2-a4a6fb9118ab, rgb(0, 44, 21)) /* {\"name\":\"Green/900\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-BWn3s [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BWn3s .framer-1765g9y { display: block; }", ".framer-BWn3s .framer-waxlco { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: 46px; justify-content: center; overflow: hidden; padding: 14px 14px 14px 14px; position: relative; text-decoration: none; width: 46px; will-change: transform; }", ".framer-BWn3s .framer-15oyq4-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 21px); position: relative; width: 18px; }", ".framer-BWn3s .framer-v-waxlco .framer-waxlco { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BWn3s .framer-waxlco { gap: 0px; } .framer-BWn3s .framer-waxlco > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-BWn3s .framer-waxlco > :first-child { margin-left: 0px; } .framer-BWn3s .framer-waxlco > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"VSifo15rr":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"lZ0cdWU_m":"link"}
 */
const FramerUYwEcrVsl: React.ComponentType<Props> = withCSS(Component, css, "framer-BWn3s") as typeof Component;
export default FramerUYwEcrVsl;

FramerUYwEcrVsl.displayName = "Elements / To-top button";

FramerUYwEcrVsl.defaultProps = {height: 46, width: 46};

addPropertyControls(FramerUYwEcrVsl, {lZ0cdWU_m: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerUYwEcrVsl, [...FeatherFonts])